document.addEventListener('DOMContentLoaded', function () {
  Fancybox.bind('[data-fancybox]', {
    on: {
      done: function (fancybox) {
        const currentSlide = fancybox.getSlide();
        const video = currentSlide.contentEl.querySelector('video');
        document.querySelector('body').classList.add('no-scroll');
        if (video) {
          video.play();
        }
      },
      close: function (fancybox) {
        const currentSlide = fancybox.getSlide();
        const video = currentSlide.contentEl.querySelector('video');
        document.querySelector('body').classList.remove('no-scroll');

        if (video) {
          video.pause();
        }
      },
    },
  });
});
